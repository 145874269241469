import { h } from 'preact';
import style from './style.css';


const Menu = (props) => {

	// function showDropdown(query){
	// 	document.querySelector("." + query).classList.toggle("active");
	// 	document.querySelector("." + query).style.display = document.querySelector("." + query).style.display === "none"? "block" : "none";
	// }

    function openNav() {
        const mq = window.matchMedia( "(max-width: 570px)" );
        document.getElementById("mySidepanel").style.width = mq.matches? "100%" : "320px";
    }
      
    function closeNav() {
        document.getElementById("mySidepanel").style.width = "0";
    }
	return (
            <>
                <button class={style["openbtn"]} onClick={openNav}><i class="fa fa-bars" /></button>  
                <div id="mySidepanel" class={style["sidepanel"]}>
                    <a href="javascript:void(0)" class={style["closebtn"]} onClick={closeNav}>×</a>
                     <div class={style["profileContainer"]}>
                         <div class={style["icon"]}>
                             JD
                         </div>
                         <div class={style["details"]}>
                             <div class={style["name"]}>Juan Dela Cruz</div>
                             <div class={style["email"]}>juandelacruz@example.com</div>
                         </div>
                     </div>
                    <a href="/dashboard" class={style["linkPages"]}><i class="fa fa-home" />Dashboard</a>
                    <a href="/products" class={style["linkPages"]}><i class="fa fa-archive" />Products</a>
                    <a href="/orders" class={style["linkPages"]}><i class="fa fa-file-text" />Orders</a>
                    <a href="/customers" class={style["linkPages"]}><i class="fa fa-users" />Customers</a>
                    <a href="/discounts" class={style["linkPages"]}><i class="fa fa-percent" />Discounts</a>
                    <a href="/offers" class={style["linkPages"]}><i class="fa fa-tags" />Offers</a>
                    <a href="/reviews" class={style["linkPages"]}><i class="fa fa-star" />Reviews</a>
                    <a href="/settings" class={style["linkPages"]}><i class="fa fa-cog" />Settings</a>
                    <a href="#" class={style["linkPages"]}><i class="fa fa-sign-out" />Logout</a>
                </div>
            </>
		// <div class={style["hamburgerContainer"]}>
		// 	<div class={style["menuToggle"]}>
        //         <input class={style["checkbox"]} type="checkbox"  onChange={toggleNav}/>
        //         <span class={style["toggle"]}></span>
        //         <span class={style["toggle"]}></span>
        //         <span class={style["toggle"]}></span>
        //         <ul class={style["menu"]} id="ul-menu-nav">
        //             <div class={style["profileContainer"]}>
        //                 <div class={style["icon"]}>
        //                     JD
        //                 </div>
        //                 <div class={style["details"]}>
        //                     <div class={style["name"]}>Juan Dela Cruz</div>
        //                     <div class={style["email"]}>juandelacruz@example.com</div>
        //                 </div>
        //             </div>
        //             <li><a href="/dashboard" class={style["linkPages"]}><i class="fa fa-home" />Dashboard</a></li>
        //             <li><a href="/products" class={style["linkPages"]}><i class="fa fa-archive" />Products</a></li>
        //             <li><a href="/orders" class={style["linkPages"]}><i class="fa fa-file-text" />Orders</a></li>
        //             <li><a href="#" class={style["linkPages"]}><i class="fa fa-users" />Customers</a></li>
        //             <li><a href="/discounts" class={style["linkPages"]}><i class="fa fa-percent" />Discounts</a></li>
        //             <li><a href="/offers" class={style["linkPages"]}><i class="fa fa-tags" />Offers</a></li>
        //             <li><a href="/reviews" class={style["linkPages"]}><i class="fa fa-star" />Reviews</a></li>
        //             <li><a href="/settings" class={style["linkPages"]}><i class="fa fa-cog" />Settings</a></li>
        //             <li><a href="#" class={style["linkPages"]}><i class="fa fa-sign-out" />Logout</a></li>
        //         </ul>
        //     </div>
		// </div>
	);
};

export default Menu;